import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import config from "../../config.json";
import axios from "axios";
import {Box} from "@mui/material";
import {FeaturesTab} from "../common/features-tab/features-tab";
import {GameVideo} from "../common/game-video/game-video";
import {SpecialFeaturesCard} from "../common/special-features-card/special-features-card";
import {GameCard} from "../common/game-card/game-card";

const Home = () => {
    const [serverStats, setServerStats] = useState({
        serverOnline: false,
        onlinePlayers: 0
    })

    const fetchOnlineStatus = async () => {
        try {
            const {data} = await axios.get(config.apiEndPoint + 'statistics')
            setServerStats({
                serverOnline: !!data?.serverOnline,
                onlinePlayers: data?.onlinePlayers ?? 0
            })
        } catch (ex) {
            console.log('failed=', ex)
        }
    }

    useEffect(() => {
        console.log('fetching status')
        fetchOnlineStatus()
    }, []);

    return (
        <Box>
            <Box className={'bg-white py-3'}>
                <Container>
                    <div className={"grid grid-cols-3 gap-5"}>
                        <div className={"col-span-1"}>
                            <FeaturesTab/>
                        </div>
                        <div className={"col-span-2"}>

                            <GameVideo/>
                        </div>
                    </div>
                </Container>
            </Box>
            <Box
                style={{
                    minHeight: "400px",
                }}
                className={"bg-black"}
            >
                <Container>
                    <Box style={{paddingTop: "50px"}}>
                        <p className={"text-4xl font-bold text-white uppercase"}>
                           Play2Earn, Mining chambers and Guild Dominator Coins Reward! (Cash out coins for real money without quota)
                        </p>
                    </Box>
                    <div className={"grid grid-cols-1 md:grid-cols-2"}>
                        <GameCard
                            title={"Server Status"}
                            value={serverStats?.serverOnline ? "ONLINE" : "OFFLINE"}
                            icon={"fa-desktop"}
                        />
                        <GameCard
                            title={"Online Players"}
                            value={serverStats?.onlinePlayers ?? 0}
                            icon={"fa-users"}
                        />

                    </div>
                </Container>
            </Box>
            <Box
                style={{
                    minHeight: "400px",
                }}
                className={"text-brand-gray-100 bg-white"}
            >
                <Container>
                    <div className={"flex justify-center"}>
                        <div className={"text-center"}>
                            <p className={"text-4xl font-bold uppercase pt-24"}>
                                Our Special Features
                            </p>
                            <p>
                                I won't lie, if you are looking for an easy server you should
                                look elsewhere.
                            </p>
                            <p>
                                {" "}
                                This is purely for people looking to regain that old, classic;
                                "nostalgia" feeling.
                            </p>
                            <p>
                                This is a server for people with skill, where a little hard work
                                goes a long long way.
                            </p>
                        </div>
                    </div>

                    <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3"}>
                        <SpecialFeaturesCard
                            title={"Closest Experience"}
                            value={
                                "Best Conquer Online experience as much as you used to experience it."
                            }
                            icon={"fa-certificate"}
                        />
                        <SpecialFeaturesCard
                            title={"Fairest Ever"}
                            value={
                                "FREE of battle power, Siding for a particular player and donation items."
                            }
                            icon={"fa-lightbulb-o"}
                        />
                        <SpecialFeaturesCard
                            title={"Simply & Clean"}
                            value={"The pure client 1.0 interface with high graphics engine."}
                            icon={"fa-podcast"}
                        />
                        <SpecialFeaturesCard
                            title={"Best Rate"}
                            value={
                                "Real low rates & experience as it was on Conquer Online 1.0."
                            }
                            icon={"fa-star"}
                        />
                        <SpecialFeaturesCard
                            title={"Advance Emulator"}
                            value={
                                "Custom Conquer online emulator to make special classic game-play."
                            }
                            icon={"fa-cog"}
                        />
                        <SpecialFeaturesCard
                            title={"BattlEye"}
                            value={
                                "Extremely anti cheat and self report to provide fair game."
                            }
                            icon={"fa-eye"}
                        />
                    </div>
                </Container>
            </Box>

        </Box>
    );
};

export default Home;
