import {Box, Typography} from "@mui/material";

export const FeaturesTab = () => {
    return (
        <Box
            className={"text-white bg-black"}
            style={{
                height: "100%",
                padding: "5%",
                borderRadius: "10px",
            }}
        >
            <Typography
                variant="h5"
                style={{
                    fontWeight: "bold",
                    marginBottom: "25px",
                }}
                className={"text-white"}

            >
                Game Features
            </Typography>
            <Box>
                <Box mb={1} justifyContent="space-between" display="flex">
                    <Box fontWeight="bold">
                        <i className="fa fa-user"/> Original Classes
                    </Box>
                    <Box>Archer Taoist Trojan & Warrior</Box>
                </Box>
                <Box mb={1} justifyContent="space-between" display="flex">
                    <Box fontWeight="bold">
                        <i className="fa fa-code-fork"/> Version
                    </Box>
                    <Box>1.0 Classic</Box>
                </Box>
                <Box mb={1} justifyContent="space-between" display="flex">
                    <Box fontWeight="bold">
                        <i className="fa fa-level-up"/> Max Level
                    </Box>
                    <Box>140</Box>
                </Box>
                <Box mb={1} justifyContent="space-between" display="flex">
                    <Box fontWeight="bold">
                        <i className="fa fa-fw fa-check"/> Rebirth
                    </Box>
                    <Box>1st Reborn Only</Box>
                </Box>
                <Box mb={1} justifyContent="space-between" display="flex">
                    <Box fontWeight="bold">
                        <i className="fa fa-fw fa-users"/> Features
                    </Box>
                    <Box>Up-to-date</Box>
                </Box>
                <Box mb={1} justifyContent="space-between" display="flex">
                    <Box fontWeight="bold">
                        <i className="fa fa-fw fa-star" /> Reset 100% every 60 days
                    </Box>
                    <Box>Nobility</Box>
                </Box>
                <Box mb={1} justifyContent="space-between" display="flex">
                    <Box fontWeight="bold">
                        <i className="fa fa-fw fa-exclamation"/> Drop Rate
                    </Box>
                    <Box>Low-Hard</Box>
                </Box>
                <Box mb={1} justifyContent="space-between" display="flex">
                    <Box fontWeight="bold">
                        <i className="fa fa-bullhorn"/> Rates
                    </Box>
                    <Box>Very Hard</Box>
                </Box>
                <Box mb={1} justifyContent="space-between" display="flex">
                    <Box fontWeight="bold">
                        <i className="fa fa-bullseye"/> Quests
                    </Box>
                    <Box>New Quests & Original</Box>
                </Box>

                <Box mb={1} justifyContent="space-between" display="flex">
                    <Box fontWeight="bold">
                        <i className="fa fa-cubes"/> Drop Gears
                    </Box>
                    <Box>PKRed/Black</Box>
                </Box>
                <Box mb={1} justifyContent="space-between" display="flex">
                    <Box fontWeight="bold">
                        <i className="fa fa-location-arrow"/> Location
                    </Box>
                    <Box>Asia</Box>
                </Box>
                <Box mb={1} justifyContent="space-between" display="flex">
                    <Box fontWeight="bold">
                        <i className="fa fa-user"/> Styling
                    </Box>
                    <Box>Cosmetic & Garments</Box>
                </Box>
            </Box>
        </Box>
    );
};
